// @import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap");

*,
*:after,
*:before {
	box-sizing: border-box;
}

.menu {
	position: relative;
	display: flex;
	justify-content: center;
}

.link {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	width: 40px;
	height: 40px;
	border-radius: 99em;
	position: relative;
	z-index: 1;
	overflow: hidden;
	transform-origin: center left;
	transition: width 0.2s ease-in;
	text-decoration: none;
	color: inherit;
	&:before {
		position: absolute;
		z-index: -1;
		content: "";
		display: block;
		border-radius: 99em;
		width: 100%;
		height: 100%;
		top: 0;
		transform: translateX(100%);
		transition: transform 0.2s ease-in;
		transform-origin: center right;
		background-color: #eee;
	}

	&:hover,
	&:focus {
		outline: 0;
		width: 170px;

		&:before,
		.link-title {
			transform: translateX(0);
			opacity: 1;
		}
	}
}

.link-icon {
	width: 28px;
	height: 28px;
	display: block;
	flex-shrink: 0;
	left: 20px;
	position: absolute;
	svg {
		width: 28px;
		height: 28px;
	}
}

.link-title {
	transform: translateX(100%);
	transition: transform 0.2s ease-in;
	transform-origin: center right;
	display: block;
	text-align: center;
	text-indent: 22px;
	width: 100%;
}
