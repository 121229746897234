// Fonts
@import url("https://fonts.googleapis.com/css2?family=Asap&display=swap");

// Variables
@import "variables";

// Bootstrap
@import "~bootstrap/scss/bootstrap";

// Bootstrap icons
@import "~bootstrap-icons/font/bootstrap-icons";

// navbar
@import "admin-navbar.scss";

