// Body
$body-bg: #f8f8f8;

// Typography
// $font-family-sans-serif: "Nunito", sans-serif;
$font-family-sans-serif: "Asap", sans-serif;
// $font-family-sans-serif: "Montserrat", sans-serif;
// $font-family-sans-serif: "Monaco", sans-serif;

$font-size-base: 0.9rem;
$line-height-base: 1.6;

$primary: #3f3f3f;
// $secondary: #c29868;
$success: #5dcd5d;
$dorado: #c29868;
$danger: #fb0303;

.text-dorado {
    color: $dorado;
}

.bg-dorado {
    background-color: $dorado;
}

.radius {
    border-radius: 50px;
}

// Input
$input-font-size: 14px;
$input-bg: #fff;
.form-control:focus {
    border-color: $primary;
    box-shadow: none;
}

// Custom scrollbar
body {
    &::-webkit-scrollbar {
        width: 10px;
        background-color: $body-bg;
    }

    &::-webkit-scrollbar-thumb {
        background: #ccc;
        border-radius: 50px;
    }
}

// Sombra
.sombra {
    box-shadow: 0px 10px 80px -10px rgba(44, 54, 92, 0.2);
}

// Card
$card-border-width: 0;
$card-border-radius: 10px;
.card {
    box-shadow: 0px 10px 80px -10px rgba(44, 54, 92, 0.2);
}

// Modals
$modal-header-border-color: rgba(0, 0, 0, 0);
$modal-header-padding: 10px 20px;
.modal-body {
    padding-top: 4px !important;
}
$modal-content-border-width: 0;

.boton-cerrar-modal {
    position: absolute;
    top: -4px;
    right: -4px;
    z-index: 2000;
    opacity: 1;
    padding: 10px;
    background-color: #fff !important;
    box-shadow: 1px 1px 4px rgba(44, 54, 92, 0.6);
    transition: all 0.15s ease;
}

.boton-cerrar-modal:hover {
    opacity: 1;
    transform: scale(1.1);
}

// Otros
.brand-link {
    text-decoration: none;
}

.separador {
    color: #bbb;
}

.desenfoque {
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(10px);
}

.cursor-pointer {
    cursor: pointer;
}

//  ----